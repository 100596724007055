:root {
  /*--primary-color: #3dc5f1;*/
  --primary-color: #1f72ba;
  --secondary-color: #3dc5f1;
  --tertiary-color: #f9d877;
  --warning-color: #ea3158;
  --highlight-color: #014e7d;
  --updated-color: rgba(243, 124, 46, 0.5);
}

@font-face {
  font-family: DmSansRegular;
  src: url(/assets/fonts/DMSans-Regular.ttf);
}

@font-face {
  font-family: DmSansBold;
  src: url(/assets/fonts/DMSans-Bold.ttf);
}

* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
textarea {
  color: #333;
  font-family: DmSansRegular, "Roboto", sans-serif;
  font-size: 16px;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: #000;
}

p {
  margin: 0 0 20px 0;
}

strong {
  font-family: DmSansBold, "Roboto", sans-serif;
}

.link {
  color: var(--primary-color);
  cursor: pointer;
}

.fieldset {
  margin: 0 0 20px 0;
}

.fieldset--button {
  padding-top: 20px;
}

.label {
  display: block;
  margin: 0 0 5px 0;
}

.label--checkmark {
  align-items: baseline;
  display: flex;
}

.center {
  text-align: center;
}

.button {
  color: #fff;
  cursor: pointer;
  background-color: var(--primary-color);
  border-radius: 5px;
  font-size: 0.8rem;
  margin: 0 10px 0 0;
  outline: none;
  padding: 10px 30px;
}

.button:hover {
  color: #fff;
}

.button--light {
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
}

.button--grade {
  font-size: 1.1rem;
  margin: 0 5px 5px 5px;
  padding: 12px 0;
  width: 50px;
}

.button--grade--disabled {
  cursor: default;
  opacity: 0.5;
}

.button--light:hover {
  color: var(--primary-color);
}

.button--small {
  font-size: 0.7rem;
  padding: 8px 12px;
}

.button--big {
  font-size: 1.1rem;
  padding: 12px 32px;
}

.button--disabled {
  opacity: 0.5;
}

.input {
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  font-size: 1rem;
  padding: 10px;
  position: relative;
  width: 300px;
  z-index: 2;
}

.input__previous {
  color: #666;
  font-size: 0.7rem;
  padding: 2px 0 0 12px;
}

input.input:read-only,
textarea.input:read-only {
  background: #f2f2f2;
  border-color: #545454;
}

input.input.input--new:read-only,
textarea.input.input--new:read-only {
  border-color: rgba(243, 124, 46, 0.5);
}

.input--medium {
  width: 500px;
}

.input--new {
  border-color: var(--updated-color);
}

.input-number {
  width: 110px;
}

.input--currency {
  width: 110px;
}

.input-percentage {
  width: 85px;
}

.input-amount {
  width: 60px;
}

.input-area {
  height: 120px;
  max-width: 700px;
  width: 100%;
}

.input-area-twin {
  width: 40%;
  min-width: auto;
}

.input-area-table {
  min-width: auto;
  height: 60px;
}

.input__sufix {
  background: var(--primary-color);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
  display: inline-block;
  line-height: 40px;
  margin-left: -5px;
  padding: 0 10px;
  position: relative;
  z-index: 1;
}

.input__sufix--new {
  background: var(--updated-color);
}

.input_box--multioptions .input_box--number .input {
  margin: 10px 0 20px 53px;
}

.input_box--multioptions .input_box--number .input__previous {
  margin: -15px 0 10px 53px;
}

.input__additional {
  margin-top: 15px;
}

.select--main {
  font-size: 1.2rem;
  font-weight: bold;
  width: auto;
}

.heading {
  font-size: 2rem;
  margin: 0 0 10px 0;
}

.heading-small {
  font-size: 1.3rem;
}

.wrap {
  margin: 0 auto;
  max-width: 1360px;
  position: relative;
}

.desktop-menu {
  align-items: center;
  display: flex;
}

.header {
  background-color: #fff;
  border-bottom: 1px solid var(--primary-color);
  height: 80px;
  padding: 15px 30px;
  position: fixed;
  width: 100%;
  z-index: 3;
}

.header__logo img {
  height: 50px;
}

.header__menu {
}

.header__company {
  flex: 1;
  padding: 0 30px 0 0;
  text-align: right;
}

.header__link {
  cursor: pointer;
  padding: 0 15px;
}

.header__link__language {
  border-radius: 3px;
  padding: 4px 6px;
}

.header__link--active {
  background-color: var(--primary-color);
  color: #fff;
}

.page {
  padding: 80px 0 0 0;
}

.page__wrapper {
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 20px 50px 20px;
  position: relative;
}

.page__sidebar {
  height: calc(100vh - 130px);
  margin: 30px 0 0 0;
  overflow: auto;
  position: fixed;
  width: 350px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.page__sidebar::-webkit-scrollbar {
  display: none;
}

.page__sidebar h2 {
  font-size: 1.1rem;
}

.page__sidebar h3 {
  font-size: 0.9rem;
  margin: 20px 0 5px 0;
}

.menu__user {
  background: #f5f5f5;
  border-radius: 10px;
  margin: 0 0 20px 0;
  padding: 20px;
}

.menu__certifier {
  background: #e6f2ea;
  border-radius: 10px;
  font-size: 0.8rem;
  margin-bottom: 20px;
  padding: 20px;
}

.menu__certifier:last-child {
  margin-bottom: 0;
}

.menu__certifier h2 {
  margin-bottom: 20px;
}

.menu__certifier h3 {
  margin: 30px 0 15px 0;
  text-transform: uppercase;
}

.menu__certifier h2 + h3 {
  margin-top: 10px;
}

.toggle__label {
  font-size: 0.9rem;
}

.toggle__label span {
  padding: 0 0 0 8px;
  position: relative;
  top: -6px;
}

.page__content {
  margin: 0 auto;
  padding: 30px 30px 30px 370px;
}

.home .page__content {
  padding-left: 30px;
}

.page__content--full {
  max-width: none;
}

.page__intro {
  margin: 0 0 30px 0;
  padding: 40px 0 0 0;
}

.article {
  line-height: 22px;
  margin: 0 0 10px 0;
}

.article h1 {
  font-size: 2rem;
  margin: 15px 0 30px 0;
}

.article h2 {
  font-size: 1.5rem;
  margin: 30px 0 15px 0;
}

.article h3 {
  font-size: 1.2rem;
  margin: 30px 0 15px 0;
}

.article p {
  margin: 0 0 15px 0;
}

.article ul {
  margin: 0 20px 15px 20px;
}

.article img {
  max-width: 80%;
}

.article .note {
  font-size: 0.7rem;
  line-height: normal;
  margin-bottom: 10px;
}

.menu {
  list-style: none;
  margin: 10px 0;
  width: 100%;
}

.menu .certification {
  margin: 30px 0 0 0;
}

.menu .certification h3 {
  margin: 0 0 15px 0;
}

.totals {
  background: #e9e9e9;
  display: flex;
  margin: 10px -20px 15px -20px;
  padding: 15px 0;
}

.totals__item {
  flex: 1;
  text-align: center;
}

.totals__item h4 {
  font-size: 0.7rem;
  margin: 0 0 8px 0;
  text-transform: uppercase;
}

.totals__item .total {
  background: var(--primary-color);
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-size: 0.8rem;
  padding: 4px 8px;
}

.page__sidebar .total--default {
  background: #bbb;
}

.page__sidebar .total--final {
  background: #27bd30;
}

.page__sidebar .total--warning {
  background: #cf0000;
}

.menu__link--main {
  display: block;
  line-height: 1.7rem;
}

.menu__link--active {
  font-family: DmSansBold;
  font-weight: bold;
}

.menu__link__info {
  color: #333;
  font-size: 0.75rem;
  display: block;
  margin: -5px 0 0 0;
}

.menu__section__title {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.menu__title {
  display: block;
  font-size: 0.9rem;
  margin: 0 0 5px 0;
}

.menu__title:last-child {
  margin-bottom: 10px;
}

.menu__title.active {
  font-weight: bold;
}

.menu__title sup {
  background: #d77b1a;
  border-radius: 2px;
  color: #fff;
  font-size: 6px;
  font-weight: bold;
  margin-left: 3px;
  padding: 2px 3px;
  text-transform: uppercase;
}

.menu__items {
  color: #999;
  line-height: 1.2rem;
  list-style: none;
  font-size: 0.8rem;
  margin: 0 0 15px 0;
}

.menu__item {
  display: flex;
  position: relative;
}

.menu__item--active {
  font-family: DmSansBold;
  font-weight: bold;
}

.menu__item .warning {
  margin: 0 0 0 3px;
}

.menu__item .important {
  margin: 0 0 0 3px;
}

.grade {
  background: var(--primary-color);
  border-radius: 3px;
  color: #fff;
  font-size: 0.55rem;
  height: 1rem;
  line-height: 1rem;
  margin: 0 0 0 5px;
  padding: 0px 4px;
  position: absolute;
  right: 0;
  top: 2px;
}

.previous-grade {
  background: center center no-repeat;
  background-size: contain;
  height: 16px;
  position: absolute;
  top: 2px;
  right: -15px;
  width: 12px;
}

.previous-grade--up {
  background-image: url(/assets/icons/up-arrow.png);
}

.previous-grade--down {
  background-image: url(/assets/icons/down-arrow.png);
}

.grade--default {
  background: #bbb;
}

.grade--final {
  background: #27bd30;
}

.grade--warning {
  background: #cf0000;
}

.grade--disabled {
  opacity: 0.6;
}

.question {
  border-radius: 10px;
  margin: 0 0 20px 0;
  position: relative;
  padding: 20px 15px 10px 15px;
}

.question--completed {
  background-color: #e6f2ea;
}

.question--advanced {
  border: 3px solid #e9e2ae;
}

.question--disabled {
  opacity: 0.4;
}

.question--rater {
  opacity: 1;
}

.question--system_disabled {
  opacity: 0.4;
}

.question--default_disabled {
  opacity: 0.4;
}

.question--advanced.question--rater .rate--question {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-bottom: -10px;
}

.question__heading {
  align-items: flex-start;
  display: flex;
}

.question__title {
  flex: 1;
  padding-right: 50px;
}

.question__title .important {
  margin: 0 0 0 5px;
}

.question__grade {
  background: #fff;
  border: 1px solid #e6f2ea;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 10px 15px;
}

.question__grade--advanced {
  background: #e9e2ae;
}

.question__grade__notes {
  background: #fff;
  border-radius: 5px;
  color: #cf0000;
  font-weight: bold;
  margin: 5px 0 0 0;
  padding: 2px 0;
  text-align: center;
}

.question__grade__ab {
  background: #fff;
  border-radius: 5px;
  color: #aaa;
  cursor: pointer;
  font-size: 0.8rem;
  margin: 5px 0 0 0;
  padding: 8px 0;
  text-align: center;
}

.question__grade__ab--checked {
  background: #e9e2ae;
  color: #000;
  font-weight: bold;
}

.question__enable {
  color: var(--primary-color);
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0 0 5px 0;
  position: relative;
  top: -10px;
}

.question__tooltip {
  margin: 0 0 0 10px;
  position: relative;
  top: 4px;
  width: 20px;
}

.article .question__title {
  margin-top: 0;
}

.question__info {
  font-style: italic;
  font-size: 0.8rem;
  margin: -3px 0 8px 0;
}

.answer__label {
  align-items: baseline;
  display: flex;
  line-height: 24px;
}

.answer__mark {
  display: inline-block;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0.7rem;
  margin: 0 6px 0 0;
  position: relative;
  text-align: center;
  top: -2px;
  width: 25px;
}

.answer__label--changed .answer__mark {
  background: rgba(243, 124, 46, 0.5);
}

.answer__input {
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  height: 150px;
  padding: 10px;
  position: relative;
  text-align: left;
  vertical-align: middle;
  top: 2px;
  width: 100%;
}

.answer__content {
  background: #f2f2f2;
  border-radius: 5px;
  margin: 0 0 10px 0;
  position: relative;
  text-align: left;
}

.answer__content--new {
  background-color: transparent;
}

.answer__content__title {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 10px;
}

.answer__content__text {
}

.answer__content__edit {
  color: var(--primary-color);
  cursor: pointer;
  font-size: 0.8rem;
  position: absolute;
  right: 15px;
  text-transform: uppercase;
  top: 0;
}

.answer__content__doc {
  cursor: pointer;
  padding: 10px 0;
}

.answer__input__box {
  margin: 0 0 5px 0;
}

.comment--preview {
  border: 1px solid #999;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 10px;
  margin: 0 0 15px 0;
  max-height: 300px;
  max-width: 700px;
  overflow: auto;
  width: 100%;
  white-space: pre-wrap;
}

.comment--preview h4 {
  font-size: 0.8rem;
  margin: 0 0 5px 0;
}

.rate {
  background: #e0f0f3;
  border-radius: 10px;
  border-top: 1px solid #fff;
  margin: 20px 0 0 0;
  padding: 5px 15px 15px 15px;
}

.rate--final {
  background: #e6f2ea;
}

.rate--final .button {
  background-color: #27bd30;
}

.rate--final .button--light {
  background-color: #fff;
  border-color: #27bd30;
  color: #27bd30;
}

.rate--question {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 15px -15px -15px -15px;
}

.rate--comment {
  background-color: #f3e0e0;
}

.rate--comment .answer__content__edit {
  color: #cf0000;
}

.rate--loading {
  opacity: 0.4;
}

.rate__main {
  flex-shrink: 0;
}

.rate__commentlist {
  margin: 10px 0 0 0;
}

.rate__commentlist_item {
  margin: 0 0 10px 0;
  position: relative;
}

.rate__commentlist_item--noncurrent {
  opacity: 0.65;
}

.rate__commentlist_author {
  font-size: 0.8rem;
  margin: 0 0 5px 0;
}

.rate__commentlist_author strong {
  font-size: 0.9rem;
}

.rate__commentlist_content {
  white-space: pre-line;
}

.rate__info {
  flex: 1;
  font-size: 0.8rem;
  padding: 0 20px;
}

.rate__info h3 {
  margin: 0 0 5px 0;
}

.rate__system {
  font-weight: bold;
  text-align: center;
}

.rate__system span {
  background: var(--primary-color);
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  margin: 10px 0 0 0;
  padding: 6px 12px;
}

.rate__content {
  font-weight: bold;
  margin: 20px 0 15px 0;
  text-align: center;
}

.rate__info__content {
  white-space: pre-wrap;
}

.rate__grade {
  text-align: center;
}

.rate__comment {
  margin: 20px 0 20px 0;
  text-align: center;
}

.rate__comment_add {
  color: var(--primary-color);
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
}

.rate__previous {
  color: #666;
  padding: 10px 0 0 0;
  text-align: center;
}

.certifiers {
  display: flex;
  margin: 30px 0 0 0;
}

.certifiers__item {
  flex: 1;
  text-align: center;
}

.certifiers__item h3 {
  margin: 0 0 10px 0;
}

.certifiers__item h4 {
  font-size: 22px;
}

.certifiers__item p {
  font-size: 0.8rem;
  margin: 10px 0 5px 0;
  padding: 0 5px;
}

.comment__save {
  margin: 15px 0 0 0;
}

.book {
  background: url(/assets/icons/book.svg) center center no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
}

.summary {
  border-collapse: collapse;
  font-size: 0.8rem;
  width: 100%;
}

.summary th {
  border: 1px solid #f2f2f2;
  padding: 5px 8px;
  text-align: center;
}

.summary .summary__header__question {
  text-align: left;
}

.summary td {
  border: 1px solid #f2f2f2;
  padding: 5px 8px;
  text-align: center;
}

.summary .summary__question {
  max-width: 150px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.summary__rating {
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}

.modal {
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 100px 0 0 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.modal__content {
}

.modal__close {
  cursor: pointer;
  font-size: 42px;
  position: absolute;
  right: 20px;
  top: 10px;
}

.loading {
  padding-top: 100px;
  text-align: center;
  width: 100%;
}

.sentence {
  padding: 2px 10px 2px 0;
}

.sentence__checkbox {
  margin: 0 8px 0 0;
  position: relative;
  top: 0;
}

.marked {
  text-decoration: line-through;
}

.text--preview {
  white-space: pre-line;
}

.table__container {
  font-size: 0.85rem;
  margin: 20px 0 50px 0;
  padding: 5px 0;
}

.table__intro {
  background: url(/assets/icons/spreadsheet.svg) left top no-repeat;
  background-size: 40px;
  padding: 0 0 10px 50px;
}

.table__intro p {
  margin: 8px 0 20px 0;
}

.table__intro p:last-child {
  margin-bottom: 10px;
}

.table__name {
  margin-bottom: 10px;
}

.table {
  border-collapse: collapse;
  font-size: 0.9rem;
  margin: 0 0 30px 0;
  width: 100%;
}

.table .input {
  font-size: 0.8rem;
}

.table .input__sufix {
  font-size: 0.8rem;
  height: 37px;
  position: relative;
  top: 1px;
}

.table th {
  border: 1px solid var(--primary-color) 20;
  background: var(--primary-color) 20;
  padding: 5px 8px;
  text-align: left;
}

.table th.center {
  text-align: center;
}

.table td {
  border: 1px solid var(--primary-color) 20;
  padding: 8px 12px;
}

.table th {
  padding: 15px 12px;
}

.table tbody th {
  font-weight: normal;
}

.table__submit {
  margin: 10px 0 0 0;
}

.table td.table__subhead {
  font-size: 1.6rem;
  font-weight: bold;
  padding: 20px;
}

.table .selected {
  background: var(--primary-color);
  color: #fff;
}

.error {
  color: #c00;
}

.notice {
  color: var(--primary-color);
  font-size: 0.8rem;
  font-weight: bold;
  margin: 10px 0 10px 0;
}

.tabs {
  display: flex;
  font-size: 0.9rem;
  margin: 40px 0 25px 0;
}

.tab {
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  margin: 0 10px 0 0;
  padding: 15px 15px;
}

.tab--active {
  background: var(--primary-color);
  color: #fff;
}

.tab--disabled {
  cursor: default;
  opacity: 0.4;
}

.additional {
  margin: 70px 0 50px 0;
}

.progress {
  color: #666;
  display: inline-block;
  font-size: 0.6rem;
  margin: 0 6px 0 0;
  text-align: right;
  width: 25px;
}

.progress--complete {
  color: #27bd27;
}

.grades {
  background: #e0f0f3;
  border-radius: 10px;
  display: flex;
  margin: 15px -15px -10px -15px;
  padding: 20px;
}

.grades__item {
  flex: 1;
  text-align: center;
}

.grades__item h3 {
  margin: 0 0 10px 0;
}

.grades__item__grade {
  font-size: 18px;
  font-weight: bold;
}

.grades__item__grade .rate__grade {
  margin: 0 auto;
  width: 200px;
}

.grades__item__comment {
  font-size: 14px;
  font-style: italic;
  margin: 10px 0 0 0;
  text-align: left;
}

.grades__item__comment_add {
  padding: 20px 0 0 0;
}

.grade__summary {
  display: flex;
  margin: 0 20px 0 0;
}

.grade__summary__items {
  margin: 0 0 20px 0;
  min-height: 100px;
}

.grade__summary .table {
  margin: 0 50px 0 0;
}

.documents {
  padding: 10px 0;
}

.file {
  background-position: 6px center;
  background-repeat: no-repeat;
  background-size: 22px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  display: inline-block;
  font-size: 0.75rem;
  margin: 0 10px 10px 0;
  padding: 6px 8px 6px 34px;
}

.file--pdf {
  background-image: url(/assets/icons/pdf.png);
}

.file--xls {
  background-image: url(/assets/icons/xls.png);
}

.file--doc {
  background-image: url(/assets/icons/doc.png);
}

.file--jpg {
  background-image: url(/assets/icons/jpg.png);
}

.file--ppt {
  background-image: url(/assets/icons/ppt.png);
}

.file--png {
  background-image: url(/assets/icons/png.png);
}

.file--default {
  background-image: url(/assets/icons/default.png);
}

.file--disabled {
  opacity: 0.7;
  text-decoration: line-through;
}

.file--disabled a {
  color: #777;
}

.file--new {
  background-color: rgba(243, 124, 46, 0.1);
  border-color: rgba(243, 124, 46, 0.5);
}

.file__delete {
  background: url(/assets/x.svg) center center no-repeat;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 8px;
  margin: 0 -2px 0 15px;
  width: 8px;
}

.__react_component_tooltip {
  line-height: 20px;
  max-width: 600px;
  padding: 15px 20px !important;
  white-space: pre-wrap;
}

.react-dropzone-s3-uploader {
  border: 0 !important;
  display: inline-block;
  height: 25px !important;
  overflow: visible !important;
  width: auto !important;
}

.react-dropzone-s3-uploader .loading {
  margin: 0;
  text-align: left;
}

.warning {
  color: #c00;
}

.important {
  color: #d77b1a;
}

.table--small {
  padding: 20px 20px 0 20px;
  max-width: 600px;
}

.table--simple {
  padding: 0;
}

.table--striped tr:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}

.table--striped th {
  background-color: #f2f2f2;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.table--striped td {
  border-bottom: 1px solid #ddd;
}

.table--simple .button {
  display: inline-block;
  margin: 0;
}

.table h2 {
  margin: 0 0 15px 0;
}

.table .content {
  font-size: 0.8rem;
  margin: 0 0 5px 0;
}

.table .small {
  font-size: 0.7rem;
  color: #666;
}

.grade__item {
  display: flex;
  margin: 10px 0;
}

.grade__label {
  flex-shrink: 0;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: left;
  width: 40px;
}

.grade__line {
  background-color: var(--primary-color);
  height: 20px;
  width: 300px;
}

.grade__value {
  font-weight: bold;
  padding: 0 0 0 10px;
}

.bookmark {
  background: url(/assets/icons/bookmark.svg) center center no-repeat;
  background-size: contain;
  height: 24px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 24px;
}

.bookmark--full {
  background-image: url(/assets/icons/bookmark-full.svg);
}

body .react-toggle--focus .react-toggle-thumb {
  box-shadow: none;
}

.page__notice {
  background: #ffcccc;
  border-radius: 999px;
  box-shadow: #ccc 0 0 5px;
  bottom: 10px;
  font-size: 0.8rem;
  left: 50%;
  margin: 0 0 0 -200px;
  padding: 10px;
  position: fixed;
  text-align: center;
  z-index: 10;
  width: 400px;
}

.copyright {
  color: #777;
  font-size: 11px;
}
