.page__report {
  padding: 30px 0;
}

.page__report h2 {
  margin: 0 0 20px 0;
}

.page__report a {
  color: var(--secondary-color);
}

.report__page__wrapper {
  clear: both;
  display: flex;
  height: 540px;
  margin: 0 0 10px 0;
  position: relative;
}

.report__page {
  background-size: cover;
  box-shadow: 0 0 15px #ccc;
  color: #000;
  display: flex;
  flex-basis: 960px;
  font-size: 1rem;
  height: 540px;
}

.report__page--ab {
  background-size: 100% 100%;
  position: relative;
}

.report__page--ab a {
  color: #000;
}

.report__page--bggrey {
  background: url(/assets/images/report-background-grey.jpg) left center
    no-repeat;
}

.report__page h1 {
  font-size: 1.8rem;
  margin: 0 0 20px 0;
}

.report__page--dark {
  background: url(/assets/images/report-background-dark.jpg) right center
    no-repeat;
  color: #fff;
}

.report__page--info {
  background: url(/assets/images/report-people.jpg) left center no-repeat;
  background-size: contain;
  padding-left: 370px;
}

.report__page--educations {
  background: url(/assets/images/report-background-educations.jpg) right center
    no-repeat;
  background-size: contain;
}

.report__page--educations h2 {
  max-width: 200px;
}

.report__page--educations p {
  max-width: 360px;
}

.report__page--educations .report__input {
  height: 280px;
  width: 450px;
}

.report_page--split_11 > div {
  flex-basis: 50%;
}

.report_page--split_21 > div:first-child {
  flex-basis: 66%;
}

.report_page--split_21 > div:nth-child(2) {
  flex-basis: 34%;
}

.report_page--split_12 > div:first-child {
  flex-basis: 34%;
}

.report_page--split_12 > div:nth-child(2) {
  flex-basis: 66%;
}

.report_page--cover {
  align-items: center;
  background: url(/assets/images/report-cover.jpg) center center no-repeat;
  background-size: cover;
  color: #fff;
  display: flex;
  padding-left: 30px;
}

.report__ab__logo {
  position: absolute;
  right: 50px;
  top: 30px;
}

.report__ab__logo img {
  width: 90px;
}

.report__page--ab--zoomed .report__ab__logo img {
  width: 120px;
}

.report__ab__badges {
  bottom: 20px;
  display: block;
  position: absolute;
  right: 30px;
}

.report__ab__badges img {
  width: 170px;
}

.report__page--ab--zoomed .report__ab__badges img {
  width: 170px;
}

.report__print {
  float: right;
  margin: -45px 390px 0 0;
}

.report__tabs {
  margin: 0 0 20px 0;
  position: relative;
  width: 600px;
}

.report__default {
  align-items: center;
  display: flex;
  justify-content: center;
}

.report__default img {
  max-height: 100%;
  max-width: 100%;
}

.report__content__column {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.report__content__header {
  font-size: 1.5rem;
  padding: 100px 50px 20px 50px;
}

.report__info {
  align-items: baseline;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.report__info h2 {
  font-family: DmSansBold;
  font-size: 1.5rem;
}

.report__info h3 {
  font-size: 1.3rem;
  font-weight: 300;
}

.report__info p {
  white-space: pre-wrap;
}

.report__info ul,
.report__info ol {
  margin: 0 0 0 20px;
}

.report__page--ab .report__info {
}

.report__page--ab .report__info .report__input--full {
  height: 300px;
  width: 600px;
}

.report__ab__info {
  padding: 0 0 0 20px;
}

.report__ab__results .report_legend {
  font-size: 0.8rem;
  margin: 0 0 0 10px;
}

.report__ab__results .report_legend_color--primary {
  background: #fad877;
}

.report__ab__results .report_legend_color--highlight {
  background: #eaeaea;
}

.report__ab__results .report_circle_chart__highlight--previous {
  stroke: #eaeaea;
}

.report__ab__results .report_circle_chart__default {
  stroke: #f9f9f9;
}

.report__page--ab .report__ab__results {
  padding-left: 60px;
}

.report__page--ab .report__ab__results .report_circle_chart__label {
  font-size: 1.8rem;
}

.report__cover {
  align-items: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 0 80px;
}

.report__chart {
  align-items: center;
  display: flex;
  font-size: 0.8rem;
  justify-content: flex-start;
}

.report__section__info {
  padding: 0 20px 0 50px;
}

.report__section--padding {
  padding-left: 80px;
  padding-right: 80px;
}

.report__section--padding-noend {
  padding-left: 80px;
  padding-right: 0;
}

.report__items {
  display: flex;
  flex: 1;
}

.report__items ul {
  margin: 20px 55px 0 16px;
}

.report__legend__label {
  color: #000;
}

.report__section--hightlighted {
  background: #e8e8e8;
}

.report_circle_chart svg {
  /*transform: rotate(-90deg);*/
}

.report_circle_chart__default {
  stroke: #e8e8e8;
}

.report_circle_chart__highlight {
  stroke: var(--secondary-color);
}

.report_circle_chart__highlight--previous {
  stroke: var(--highlight-color);
}

.gold .report_circle_chart__highlight {
  stroke: var(--tertiary-color);
}

.report_circle_chart__line {
  stroke: var(--warning-color);
}

.report_circle_chart__label {
  font-family: DmSansBold;
  font-size: 3rem;
  font-weight: 700;
}

.report_confirmation__info {
  padding: 60px 0 0 0;
}

.report_confirmation__info h1 {
  font-size: 2rem;
  font-weight: 300;
  margin: 0 0 30px 0;
}

.report_highlights {
  background: url(/assets/images/report-background-blue-side.jpg) center center
    no-repeat;
  background-size: cover;
  display: flex;
}

.report_highlights__list {
  align-items: center;
  display: flex;
  flex-basis: 65%;
  flex-shrink: 0;
  line-height: 1.2rem;
  padding: 0 50px 0 80px;
  white-space: pre-wrap;
}

.report_highlights__list .report__input {
  height: 380px;
  width: 420px;
}

.report_highlights__heading {
  align-items: center;
  color: #fff;
  display: flex;
  padding: 80px;
}

.report_highlights__heading h2 {
  font-family: DmSansBold;
  font-size: 1.5rem;
  text-align: right;
}

.report_ab_highlights {
  padding: 60px 0 0 0;
}

.report_ab_highlights h2 {
  font-family: DmSansBold;
  font-size: 1.45rem;
  margin-bottom: 30px;
}

.report_ab_highlights .report__input {
  height: 380px;
  width: 480px;
}

body .report_ab_highlights .ql-editor ol,
body .report_ab_highlights .ql-editor ul {
  font-size: 0.85rem;
}

.report_outro {
  align-items: center;
  background: url(/assets/images/report-outro-new.jpg) center center no-repeat;
  background-size: cover;
  color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.report_outro a {
  color: #fff;
}

.report_outro__content {
  align-items: center;
  display: flex;
  padding: 60px 0;
}

.report_outro__info {
  align-items: center;
  display: flex;
  padding-bottom: 150px;
  text-align: center;
}

.report_outro__info__title {
  border-right: 3px solid #fff;
  font-family: DmSansBold;
  font-size: 2.5rem;
  padding: 20px 30px 20px 10px;
  text-align: left;
}

.report_outro__info__content {
  padding: 20px 0 20px 60px;
  text-align: left;
}

.report_outro__info__title span {
  color: var(--secondary-color);
}

.report_outro__info__selectio {
  padding-right: 650px;
}

.report_outro__info__selectio img {
  height: 50px;
}

.report_outro__info__logos {
  line-height: 45px;
}

.report_outro__info__logos img {
  height: 45px;
  vertical-align: middle;
}

.logo_label {
  padding: 0 0 0 30px;
}

.report__input {
  border: 1px solid #ccc;
  font-size: 0.9rem;
  height: 450px;
  width: 340px;
}

.report__input--full {
  height: 410px;
  width: 790px;
}

.report__input--smaller {
  font-size: 0.8rem;
}

.report__options {
  background: #fff;
  border: 1px solid #999;
  flex: 1;
  font-size: 0.8rem;
  margin: 0 0 0 50px;
  overflow: auto;
}

.report__options__tabs {
  display: flex;
}

.report__options__tabs div {
  background: #e8e8e8;
  color: #999;
  cursor: pointer;
  flex: 1;
  font-size: 1rem;
  padding: 20px;
  text-align: center;
}

.report__options__tabs .active {
  background: #fff;
  color: #000;
}

.report__options__tab__content {
  display: none;
  padding: 20px;
}

.report__options__tab__content.active {
  display: block;
}

.report__options__checkitem {
  margin: 0;
}

.report__options__checkitem p {
  margin: 0;
  padding: 8px;
}

.report__options__checkitem p:hover {
  background: #eaeaea;
  cursor: pointer;
}

.report_certifier_list {
  display: flex;
  margin: 40px 0 0 0;
}

.report_certifier {
  flex: 1;
  text-align: center;
}

.report_certifier h2 {
  font-size: 1rem;
  margin-bottom: 5px;
}

.report_certifier p {
  font-size: 0.9rem;
  margin: 0;
}

.report_category__side {
  background: url(/assets/images/report-background-grey.jpg) -40px center no-repeat;
  background-size: cover;
  align-items: flex-start;
  display: flex;
  flex-basis: 360px;
  flex-direction: column;
  justify-content: center;
  padding-left: 60px;
}

.report_category__side h2 {
  font-size: 1.1rem;
  text-align: left;
  width: 100%;
}

.report_category__side .report_circle_chart__label {
  font-size: 1.3rem;
}

.report_category__side .report_circle_chart__default {
  stroke: #fff;
}

.report_category__content {
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 0.8rem;
  justify-content: left;
}

.report_category__content .recharts-wrapper {
  left: -70px;
}

.report_category__highlights {
  flex: 1;
  padding: 50px 0 0 0;
}

.report_category__highlights h2 {
  margin: 0 0 15px 0;
}

.report_category__highlights .report__input {
  height: 380px;
  width: 490px;
}

.report__timeline {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.report__timeline__line {
  height: 400px;
  width: 20px;
}

.report__timeline__line:first-child {
  border-right: 1px solid #ccc;
}

.report__timeline__line:nth-child(2) {
  border-left: 1px solid #ccc;
}

.report__timeline__item {
  left: 50%;
  margin-left: -10px;
  position: absolute;
  padding: 0 0 0 35px;
}

.report__timeline__item--left {
  left: auto;
  margin-right: -10px;
  right: 50%;
  text-align: right;
  padding: 0 35px 0 0;
}

.report__timeline__item__dot {
  position: absolute;
  background: var(--secondary-color);
  border-radius: 999px;
  height: 20px;
  width: 20px;
  left: 0;
  top: 10px;
}

.report__timeline__item--left .report__timeline__item__dot {
  left: auto;
  right: 0;
}

.report__timeline__item__title {
  font-weight: bold;
}

.report__timeline__item__subtitle {
  font-size: 0.9rem;
}

.report__badge {
  background: url(/assets/images/report-badge.png) center center no-repeat;
  background-size: contain;
  height: 40px;
  left: 880px;
  position: absolute;
  top: 460px;
  width: 40px;
}

.report__badge--light {
  background-image: url(/assets/images/report-badge-light.png);
}

.report_benchmark {
  display: flex;
  height: 300px;
  margin: 40px 0 0 0;
}

.report_benchmark__reference {
  height: 320px;
  left: 630px;
  position: absolute;
}

.report__page--ab .report_benchmark__reference {
  height: 205px;
}

.report_benchmark__reference__line {
  border-left: 1px dashed #ccc;
  height: 100%;
}

.report_benchmark__reference__label {
  font-size: 0.7rem;
  left: -75px;
  position: absolute;
  top: -25px;
  width: 150px;
}

.report_benchmark__items {
  flex: 1;
  padding: 20px 0 0 0;
}

.report_benchmark__item {
  align-items: center;
  display: flex;
  flex: 1;
  position: relative;
  margin: 0 0 20px 0;
  width: 760px;
}

.report_benchmark__item__label {
  flex-shrink: 0;
  font-size: 0.8rem;
  padding: 0 30px 0 0;
  width: 350px;
}

.report_benchmark__item__line {
  flex: 1;
  background: #eaeaea;
}

.report_benchmark__item__line__indicator {
  background: var(--secondary-color);
  height: 8px;
}

.report__page--ab .report_benchmark__item__line__indicator {
  background: var(--tertiary-color);
}

.report_benchmark__item__reference {
  color: #666;
  font-size: 0.6rem;
  left: 558px;
  position: absolute;
  top: -11px;
}

.report__benchmark {
  padding: 20px;
}

.report__benchmark h3 {
  margin: 0 0 20px 0;
}

.report__benchmark .input {
  width: 100%;
}

.report_legend {
  margin: 0 0 0 60px;
}

.report_legend_item {
  display: inline-block;
}

.report_legend_color {
  background-color: #c00;
  display: inline-block;
  height: 11px;
  width: 14px;
}

.report_legend_color--primary {
  background-color: var(--secondary-color);
}

.report_legend_color--highlight {
  background-color: var(--highlight-color);
}

.report_legend_label {
  display: inline-block;
  margin: 0 20px 0 5px;
}

.subtitle {
  margin-top: -20px;
}

.recharts-label-list .recharts-label {
  font-size: 0.5rem;
}

.report__ab__chart {
  font-size: 0.8rem;
  padding-right: 60px;
  position: relative;
  top: 50px;
}

.report__ab__chart .recharts-label-list .recharts-label {
  font-size: 0.7rem;
}

#page-elc-benchmark .recharts-label-list .recharts-label {
  font-size: 0.5rem;
}

.report_chart--overall .recharts-label-list .recharts-label {
  font-size: 2.2rem;
  font-family: DmSansBold;
}

.report_midtitle_section {
  align-items: center;
  flex-direction: row;
}

.report_midtitle {
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  flex-basis: 260px;
  flex-grow: 0;
  flex-shrink: 0;
}

.report__risks .report__page {
  background-position-x: -50px;
  background-repeat: no-repeat;
}

.report__risks .report__section--padding {
  padding-left: 60px;
}

body .recharts-legend-wrapper {
  width: 300px !important;
}

body .ql-container {
  font-family: inherit;
  font-size: inherit;
}

body .ql-editor {
  padding: 0;
  overflow-y: hidden;
}

body .ql-editor p {
  font-size: 1rem;
  margin: 0 0 20px 0;
}

body .ql-editor ol,
body .ql-editor ul {
  font-size: 0.9rem;
  margin: 0 0 0 20px;
  padding: 0;
}

body .ql-editor li.ql-indent-1 {
  margin: 0 0 0 20px;
  padding-left: 0;
}

body .ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 0;
}

body .report__input--smaller .ql-editor ol,
body .report__input--smaller .ql-editor ul,
body .report__input--smaller .ql-editor p {
  font-size: 0.85rem;
}

body .ql-editor ol li:not(.ql-direction-rtl),
body .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 0;
}

body .ql-editor ol > li {
  list-style-type: decimal;
}

body .ql-editor ul > li {
  list-style-type: disc;
}

body .ql-editor li:not(.ql-direction-rtl)::before {
  display: none;
}

body .ql-editor ol li:marker,
body .ql-editor ul li:marker {
  font-size: 2rem;
}

@media print {
  @page {
    margin: 0;
    size: 9.6in 5.4in;
  }

  .page {
    padding: 0;
  }

  .header {
    display: none;
  }

  .page__wrapper {
    padding: 0;
  }

  .page__report {
    padding: 0;
  }

  .page__report > h2 {
    display: none;
  }

  .page__notice {
    display: none;
  }

  .report__page {
    box-shadow: none;
    flex-basis: 100%;
    height: 100vh;
  }

  .report__print {
    display: none;
  }

  .report__options {
    display: none;
  }

  .report__tabs {
    display: none;
  }

  .report__badge {
    left: 850px;
    top: 445px;
  }

  .report__page__wrapper {
    height: auto;
    margin: 0;
    page-break-after: always;
  }

  .report__page__wrapper:has(.ql-blank) {
    display: none;
    page-break-after: avoid;
  }

  .report__page__wrapper:last-child {
    page-break-after: avoid;
  }

  .report__input {
    border-color: #fff;
  }
}
