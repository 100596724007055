:root {
  /*--primary-color: #3dc5f1;*/
  --primary-color: #1f72ba;
  --secondary-color: #3dc5f1;
  --tertiary-color: #f9d877;
  --warning-color: #ea3158;
  --highlight-color: #014e7d;
  --updated-color: rgba(243, 124, 46, 0.5);
}

@font-face {
  font-family: DmSansRegular;
  src: url(/assets/fonts/DMSans-Regular.ttf);
}

@font-face {
  font-family: DmSansBold;
  src: url(/assets/fonts/DMSans-Bold.ttf);
}

* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
textarea {
  color: #333;
  font-family: DmSansRegular, "Roboto", sans-serif;
  font-size: 16px;
}

a {
  color: #1f72ba;
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: #000;
}

p {
  margin: 0 0 20px 0;
}

strong {
  font-family: DmSansBold, "Roboto", sans-serif;
}

.link {
  color: #1f72ba;
  color: var(--primary-color);
  cursor: pointer;
}

.fieldset {
  margin: 0 0 20px 0;
}

.fieldset--button {
  padding-top: 20px;
}

.label {
  display: block;
  margin: 0 0 5px 0;
}

.label--checkmark {
  align-items: baseline;
  display: flex;
}

.center {
  text-align: center;
}

.button {
  color: #fff;
  cursor: pointer;
  background-color: #1f72ba;
  background-color: var(--primary-color);
  border-radius: 5px;
  font-size: 0.8rem;
  margin: 0 10px 0 0;
  outline: none;
  padding: 10px 30px;
}

.button:hover {
  color: #fff;
}

.button--light {
  color: #1f72ba;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid #1f72ba;
  border: 1px solid var(--primary-color);
}

.button--grade {
  font-size: 1.1rem;
  margin: 0 5px 5px 5px;
  padding: 12px 0;
  width: 50px;
}

.button--grade--disabled {
  cursor: default;
  opacity: 0.5;
}

.button--light:hover {
  color: #1f72ba;
  color: var(--primary-color);
}

.button--small {
  font-size: 0.7rem;
  padding: 8px 12px;
}

.button--big {
  font-size: 1.1rem;
  padding: 12px 32px;
}

.button--disabled {
  opacity: 0.5;
}

.input {
  border: 1px solid #1f72ba;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  font-size: 1rem;
  padding: 10px;
  position: relative;
  width: 300px;
  z-index: 2;
}

.input__previous {
  color: #666;
  font-size: 0.7rem;
  padding: 2px 0 0 12px;
}

input.input:read-only,
textarea.input:read-only {
  background: #f2f2f2;
  border-color: #545454;
}

input.input.input--new:read-only,
textarea.input.input--new:read-only {
  border-color: rgba(243, 124, 46, 0.5);
}

.input--medium {
  width: 500px;
}

.input--new {
  border-color: rgba(243, 124, 46, 0.5);
  border-color: var(--updated-color);
}

.input-number {
  width: 110px;
}

.input--currency {
  width: 110px;
}

.input-percentage {
  width: 85px;
}

.input-amount {
  width: 60px;
}

.input-area {
  height: 120px;
  max-width: 700px;
  width: 100%;
}

.input-area-twin {
  width: 40%;
  min-width: auto;
}

.input-area-table {
  min-width: auto;
  height: 60px;
}

.input__sufix {
  background: #1f72ba;
  background: var(--primary-color);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
  display: inline-block;
  line-height: 40px;
  margin-left: -5px;
  padding: 0 10px;
  position: relative;
  z-index: 1;
}

.input__sufix--new {
  background: rgba(243, 124, 46, 0.5);
  background: var(--updated-color);
}

.input_box--multioptions .input_box--number .input {
  margin: 10px 0 20px 53px;
}

.input_box--multioptions .input_box--number .input__previous {
  margin: -15px 0 10px 53px;
}

.input__additional {
  margin-top: 15px;
}

.select--main {
  font-size: 1.2rem;
  font-weight: bold;
  width: auto;
}

.heading {
  font-size: 2rem;
  margin: 0 0 10px 0;
}

.heading-small {
  font-size: 1.3rem;
}

.wrap {
  margin: 0 auto;
  max-width: 1360px;
  position: relative;
}

.desktop-menu {
  align-items: center;
  display: flex;
}

.header {
  background-color: #fff;
  border-bottom: 1px solid #1f72ba;
  border-bottom: 1px solid var(--primary-color);
  height: 80px;
  padding: 15px 30px;
  position: fixed;
  width: 100%;
  z-index: 3;
}

.header__logo img {
  height: 50px;
}

.header__menu {
}

.header__company {
  flex: 1 1;
  padding: 0 30px 0 0;
  text-align: right;
}

.header__link {
  cursor: pointer;
  padding: 0 15px;
}

.header__link__language {
  border-radius: 3px;
  padding: 4px 6px;
}

.header__link--active {
  background-color: #1f72ba;
  background-color: var(--primary-color);
  color: #fff;
}

.page {
  padding: 80px 0 0 0;
}

.page__wrapper {
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 20px 50px 20px;
  position: relative;
}

.page__sidebar {
  height: calc(100vh - 130px);
  margin: 30px 0 0 0;
  overflow: auto;
  position: fixed;
  width: 350px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.page__sidebar::-webkit-scrollbar {
  display: none;
}

.page__sidebar h2 {
  font-size: 1.1rem;
}

.page__sidebar h3 {
  font-size: 0.9rem;
  margin: 20px 0 5px 0;
}

.menu__user {
  background: #f5f5f5;
  border-radius: 10px;
  margin: 0 0 20px 0;
  padding: 20px;
}

.menu__certifier {
  background: #e6f2ea;
  border-radius: 10px;
  font-size: 0.8rem;
  margin-bottom: 20px;
  padding: 20px;
}

.menu__certifier:last-child {
  margin-bottom: 0;
}

.menu__certifier h2 {
  margin-bottom: 20px;
}

.menu__certifier h3 {
  margin: 30px 0 15px 0;
  text-transform: uppercase;
}

.menu__certifier h2 + h3 {
  margin-top: 10px;
}

.toggle__label {
  font-size: 0.9rem;
}

.toggle__label span {
  padding: 0 0 0 8px;
  position: relative;
  top: -6px;
}

.page__content {
  margin: 0 auto;
  padding: 30px 30px 30px 370px;
}

.home .page__content {
  padding-left: 30px;
}

.page__content--full {
  max-width: none;
}

.page__intro {
  margin: 0 0 30px 0;
  padding: 40px 0 0 0;
}

.article {
  line-height: 22px;
  margin: 0 0 10px 0;
}

.article h1 {
  font-size: 2rem;
  margin: 15px 0 30px 0;
}

.article h2 {
  font-size: 1.5rem;
  margin: 30px 0 15px 0;
}

.article h3 {
  font-size: 1.2rem;
  margin: 30px 0 15px 0;
}

.article p {
  margin: 0 0 15px 0;
}

.article ul {
  margin: 0 20px 15px 20px;
}

.article img {
  max-width: 80%;
}

.article .note {
  font-size: 0.7rem;
  line-height: normal;
  margin-bottom: 10px;
}

.menu {
  list-style: none;
  margin: 10px 0;
  width: 100%;
}

.menu .certification {
  margin: 30px 0 0 0;
}

.menu .certification h3 {
  margin: 0 0 15px 0;
}

.totals {
  background: #e9e9e9;
  display: flex;
  margin: 10px -20px 15px -20px;
  padding: 15px 0;
}

.totals__item {
  flex: 1 1;
  text-align: center;
}

.totals__item h4 {
  font-size: 0.7rem;
  margin: 0 0 8px 0;
  text-transform: uppercase;
}

.totals__item .total {
  background: #1f72ba;
  background: var(--primary-color);
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-size: 0.8rem;
  padding: 4px 8px;
}

.page__sidebar .total--default {
  background: #bbb;
}

.page__sidebar .total--final {
  background: #27bd30;
}

.page__sidebar .total--warning {
  background: #cf0000;
}

.menu__link--main {
  display: block;
  line-height: 1.7rem;
}

.menu__link--active {
  font-family: DmSansBold;
  font-weight: bold;
}

.menu__link__info {
  color: #333;
  font-size: 0.75rem;
  display: block;
  margin: -5px 0 0 0;
}

.menu__section__title {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.menu__title {
  display: block;
  font-size: 0.9rem;
  margin: 0 0 5px 0;
}

.menu__title:last-child {
  margin-bottom: 10px;
}

.menu__title.active {
  font-weight: bold;
}

.menu__title sup {
  background: #d77b1a;
  border-radius: 2px;
  color: #fff;
  font-size: 6px;
  font-weight: bold;
  margin-left: 3px;
  padding: 2px 3px;
  text-transform: uppercase;
}

.menu__items {
  color: #999;
  line-height: 1.2rem;
  list-style: none;
  font-size: 0.8rem;
  margin: 0 0 15px 0;
}

.menu__item {
  display: flex;
  position: relative;
}

.menu__item--active {
  font-family: DmSansBold;
  font-weight: bold;
}

.menu__item .warning {
  margin: 0 0 0 3px;
}

.menu__item .important {
  margin: 0 0 0 3px;
}

.grade {
  background: #1f72ba;
  background: var(--primary-color);
  border-radius: 3px;
  color: #fff;
  font-size: 0.55rem;
  height: 1rem;
  line-height: 1rem;
  margin: 0 0 0 5px;
  padding: 0px 4px;
  position: absolute;
  right: 0;
  top: 2px;
}

.previous-grade {
  background: center center no-repeat;
  background-size: contain;
  height: 16px;
  position: absolute;
  top: 2px;
  right: -15px;
  width: 12px;
}

.previous-grade--up {
  background-image: url(/assets/icons/up-arrow.png);
}

.previous-grade--down {
  background-image: url(/assets/icons/down-arrow.png);
}

.grade--default {
  background: #bbb;
}

.grade--final {
  background: #27bd30;
}

.grade--warning {
  background: #cf0000;
}

.grade--disabled {
  opacity: 0.6;
}

.question {
  border-radius: 10px;
  margin: 0 0 20px 0;
  position: relative;
  padding: 20px 15px 10px 15px;
}

.question--completed {
  background-color: #e6f2ea;
}

.question--advanced {
  border: 3px solid #e9e2ae;
}

.question--disabled {
  opacity: 0.4;
}

.question--rater {
  opacity: 1;
}

.question--system_disabled {
  opacity: 0.4;
}

.question--default_disabled {
  opacity: 0.4;
}

.question--advanced.question--rater .rate--question {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-bottom: -10px;
}

.question__heading {
  align-items: flex-start;
  display: flex;
}

.question__title {
  flex: 1 1;
  padding-right: 50px;
}

.question__title .important {
  margin: 0 0 0 5px;
}

.question__grade {
  background: #fff;
  border: 1px solid #e6f2ea;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 10px 15px;
}

.question__grade--advanced {
  background: #e9e2ae;
}

.question__grade__notes {
  background: #fff;
  border-radius: 5px;
  color: #cf0000;
  font-weight: bold;
  margin: 5px 0 0 0;
  padding: 2px 0;
  text-align: center;
}

.question__grade__ab {
  background: #fff;
  border-radius: 5px;
  color: #aaa;
  cursor: pointer;
  font-size: 0.8rem;
  margin: 5px 0 0 0;
  padding: 8px 0;
  text-align: center;
}

.question__grade__ab--checked {
  background: #e9e2ae;
  color: #000;
  font-weight: bold;
}

.question__enable {
  color: #1f72ba;
  color: var(--primary-color);
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0 0 5px 0;
  position: relative;
  top: -10px;
}

.question__tooltip {
  margin: 0 0 0 10px;
  position: relative;
  top: 4px;
  width: 20px;
}

.article .question__title {
  margin-top: 0;
}

.question__info {
  font-style: italic;
  font-size: 0.8rem;
  margin: -3px 0 8px 0;
}

.answer__label {
  align-items: baseline;
  display: flex;
  line-height: 24px;
}

.answer__mark {
  display: inline-block;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0.7rem;
  margin: 0 6px 0 0;
  position: relative;
  text-align: center;
  top: -2px;
  width: 25px;
}

.answer__label--changed .answer__mark {
  background: rgba(243, 124, 46, 0.5);
}

.answer__input {
  border: 1px solid #1f72ba;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  height: 150px;
  padding: 10px;
  position: relative;
  text-align: left;
  vertical-align: middle;
  top: 2px;
  width: 100%;
}

.answer__content {
  background: #f2f2f2;
  border-radius: 5px;
  margin: 0 0 10px 0;
  position: relative;
  text-align: left;
}

.answer__content--new {
  background-color: transparent;
}

.answer__content__title {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 10px;
}

.answer__content__text {
}

.answer__content__edit {
  color: #1f72ba;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 0.8rem;
  position: absolute;
  right: 15px;
  text-transform: uppercase;
  top: 0;
}

.answer__content__doc {
  cursor: pointer;
  padding: 10px 0;
}

.answer__input__box {
  margin: 0 0 5px 0;
}

.comment--preview {
  border: 1px solid #999;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 10px;
  margin: 0 0 15px 0;
  max-height: 300px;
  max-width: 700px;
  overflow: auto;
  width: 100%;
  white-space: pre-wrap;
}

.comment--preview h4 {
  font-size: 0.8rem;
  margin: 0 0 5px 0;
}

.rate {
  background: #e0f0f3;
  border-radius: 10px;
  border-top: 1px solid #fff;
  margin: 20px 0 0 0;
  padding: 5px 15px 15px 15px;
}

.rate--final {
  background: #e6f2ea;
}

.rate--final .button {
  background-color: #27bd30;
}

.rate--final .button--light {
  background-color: #fff;
  border-color: #27bd30;
  color: #27bd30;
}

.rate--question {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 15px -15px -15px -15px;
}

.rate--comment {
  background-color: #f3e0e0;
}

.rate--comment .answer__content__edit {
  color: #cf0000;
}

.rate--loading {
  opacity: 0.4;
}

.rate__main {
  flex-shrink: 0;
}

.rate__commentlist {
  margin: 10px 0 0 0;
}

.rate__commentlist_item {
  margin: 0 0 10px 0;
  position: relative;
}

.rate__commentlist_item--noncurrent {
  opacity: 0.65;
}

.rate__commentlist_author {
  font-size: 0.8rem;
  margin: 0 0 5px 0;
}

.rate__commentlist_author strong {
  font-size: 0.9rem;
}

.rate__commentlist_content {
  white-space: pre-line;
}

.rate__info {
  flex: 1 1;
  font-size: 0.8rem;
  padding: 0 20px;
}

.rate__info h3 {
  margin: 0 0 5px 0;
}

.rate__system {
  font-weight: bold;
  text-align: center;
}

.rate__system span {
  background: #1f72ba;
  background: var(--primary-color);
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  margin: 10px 0 0 0;
  padding: 6px 12px;
}

.rate__content {
  font-weight: bold;
  margin: 20px 0 15px 0;
  text-align: center;
}

.rate__info__content {
  white-space: pre-wrap;
}

.rate__grade {
  text-align: center;
}

.rate__comment {
  margin: 20px 0 20px 0;
  text-align: center;
}

.rate__comment_add {
  color: #1f72ba;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
}

.rate__previous {
  color: #666;
  padding: 10px 0 0 0;
  text-align: center;
}

.certifiers {
  display: flex;
  margin: 30px 0 0 0;
}

.certifiers__item {
  flex: 1 1;
  text-align: center;
}

.certifiers__item h3 {
  margin: 0 0 10px 0;
}

.certifiers__item h4 {
  font-size: 22px;
}

.certifiers__item p {
  font-size: 0.8rem;
  margin: 10px 0 5px 0;
  padding: 0 5px;
}

.comment__save {
  margin: 15px 0 0 0;
}

.book {
  background: url(/assets/icons/book.svg) center center no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
}

.summary {
  border-collapse: collapse;
  font-size: 0.8rem;
  width: 100%;
}

.summary th {
  border: 1px solid #f2f2f2;
  padding: 5px 8px;
  text-align: center;
}

.summary .summary__header__question {
  text-align: left;
}

.summary td {
  border: 1px solid #f2f2f2;
  padding: 5px 8px;
  text-align: center;
}

.summary .summary__question {
  max-width: 150px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.summary__rating {
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}

.modal {
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 100px 0 0 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.modal__content {
}

.modal__close {
  cursor: pointer;
  font-size: 42px;
  position: absolute;
  right: 20px;
  top: 10px;
}

.loading {
  padding-top: 100px;
  text-align: center;
  width: 100%;
}

.sentence {
  padding: 2px 10px 2px 0;
}

.sentence__checkbox {
  margin: 0 8px 0 0;
  position: relative;
  top: 0;
}

.marked {
  text-decoration: line-through;
}

.text--preview {
  white-space: pre-line;
}

.table__container {
  font-size: 0.85rem;
  margin: 20px 0 50px 0;
  padding: 5px 0;
}

.table__intro {
  background: url(/assets/icons/spreadsheet.svg) left top no-repeat;
  background-size: 40px;
  padding: 0 0 10px 50px;
}

.table__intro p {
  margin: 8px 0 20px 0;
}

.table__intro p:last-child {
  margin-bottom: 10px;
}

.table__name {
  margin-bottom: 10px;
}

.table {
  border-collapse: collapse;
  font-size: 0.9rem;
  margin: 0 0 30px 0;
  width: 100%;
}

.table .input {
  font-size: 0.8rem;
}

.table .input__sufix {
  font-size: 0.8rem;
  height: 37px;
  position: relative;
  top: 1px;
}

.table th {
  border: 1px solid #1f72ba 20;
  border: 1px solid var(--primary-color) 20;
  background: #1f72ba 20;
  background: var(--primary-color) 20;
  padding: 5px 8px;
  text-align: left;
}

.table th.center {
  text-align: center;
}

.table td {
  border: 1px solid #1f72ba 20;
  border: 1px solid var(--primary-color) 20;
  padding: 8px 12px;
}

.table th {
  padding: 15px 12px;
}

.table tbody th {
  font-weight: normal;
}

.table__submit {
  margin: 10px 0 0 0;
}

.table td.table__subhead {
  font-size: 1.6rem;
  font-weight: bold;
  padding: 20px;
}

.table .selected {
  background: #1f72ba;
  background: var(--primary-color);
  color: #fff;
}

.error {
  color: #c00;
}

.notice {
  color: #1f72ba;
  color: var(--primary-color);
  font-size: 0.8rem;
  font-weight: bold;
  margin: 10px 0 10px 0;
}

.tabs {
  display: flex;
  font-size: 0.9rem;
  margin: 40px 0 25px 0;
}

.tab {
  border: 1px solid #1f72ba;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  margin: 0 10px 0 0;
  padding: 15px 15px;
}

.tab--active {
  background: #1f72ba;
  background: var(--primary-color);
  color: #fff;
}

.tab--disabled {
  cursor: default;
  opacity: 0.4;
}

.additional {
  margin: 70px 0 50px 0;
}

.progress {
  color: #666;
  display: inline-block;
  font-size: 0.6rem;
  margin: 0 6px 0 0;
  text-align: right;
  width: 25px;
}

.progress--complete {
  color: #27bd27;
}

.grades {
  background: #e0f0f3;
  border-radius: 10px;
  display: flex;
  margin: 15px -15px -10px -15px;
  padding: 20px;
}

.grades__item {
  flex: 1 1;
  text-align: center;
}

.grades__item h3 {
  margin: 0 0 10px 0;
}

.grades__item__grade {
  font-size: 18px;
  font-weight: bold;
}

.grades__item__grade .rate__grade {
  margin: 0 auto;
  width: 200px;
}

.grades__item__comment {
  font-size: 14px;
  font-style: italic;
  margin: 10px 0 0 0;
  text-align: left;
}

.grades__item__comment_add {
  padding: 20px 0 0 0;
}

.grade__summary {
  display: flex;
  margin: 0 20px 0 0;
}

.grade__summary__items {
  margin: 0 0 20px 0;
  min-height: 100px;
}

.grade__summary .table {
  margin: 0 50px 0 0;
}

.documents {
  padding: 10px 0;
}

.file {
  background-position: 6px center;
  background-repeat: no-repeat;
  background-size: 22px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  display: inline-block;
  font-size: 0.75rem;
  margin: 0 10px 10px 0;
  padding: 6px 8px 6px 34px;
}

.file--pdf {
  background-image: url(/assets/icons/pdf.png);
}

.file--xls {
  background-image: url(/assets/icons/xls.png);
}

.file--doc {
  background-image: url(/assets/icons/doc.png);
}

.file--jpg {
  background-image: url(/assets/icons/jpg.png);
}

.file--ppt {
  background-image: url(/assets/icons/ppt.png);
}

.file--png {
  background-image: url(/assets/icons/png.png);
}

.file--default {
  background-image: url(/assets/icons/default.png);
}

.file--disabled {
  opacity: 0.7;
  text-decoration: line-through;
}

.file--disabled a {
  color: #777;
}

.file--new {
  background-color: rgba(243, 124, 46, 0.1);
  border-color: rgba(243, 124, 46, 0.5);
}

.file__delete {
  background: url(/assets/x.svg) center center no-repeat;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 8px;
  margin: 0 -2px 0 15px;
  width: 8px;
}

.__react_component_tooltip {
  line-height: 20px;
  max-width: 600px;
  padding: 15px 20px !important;
  white-space: pre-wrap;
}

.react-dropzone-s3-uploader {
  border: 0 !important;
  display: inline-block;
  height: 25px !important;
  overflow: visible !important;
  width: auto !important;
}

.react-dropzone-s3-uploader .loading {
  margin: 0;
  text-align: left;
}

.warning {
  color: #c00;
}

.important {
  color: #d77b1a;
}

.table--small {
  padding: 20px 20px 0 20px;
  max-width: 600px;
}

.table--simple {
  padding: 0;
}

.table--striped tr:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}

.table--striped th {
  background-color: #f2f2f2;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.table--striped td {
  border-bottom: 1px solid #ddd;
}

.table--simple .button {
  display: inline-block;
  margin: 0;
}

.table h2 {
  margin: 0 0 15px 0;
}

.table .content {
  font-size: 0.8rem;
  margin: 0 0 5px 0;
}

.table .small {
  font-size: 0.7rem;
  color: #666;
}

.grade__item {
  display: flex;
  margin: 10px 0;
}

.grade__label {
  flex-shrink: 0;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: left;
  width: 40px;
}

.grade__line {
  background-color: #1f72ba;
  background-color: var(--primary-color);
  height: 20px;
  width: 300px;
}

.grade__value {
  font-weight: bold;
  padding: 0 0 0 10px;
}

.bookmark {
  background: url(/assets/icons/bookmark.svg) center center no-repeat;
  background-size: contain;
  height: 24px;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 24px;
}

.bookmark--full {
  background-image: url(/assets/icons/bookmark-full.svg);
}

body .react-toggle--focus .react-toggle-thumb {
  box-shadow: none;
}

.page__notice {
  background: #ffcccc;
  border-radius: 999px;
  box-shadow: #ccc 0 0 5px;
  bottom: 10px;
  font-size: 0.8rem;
  left: 50%;
  margin: 0 0 0 -200px;
  padding: 10px;
  position: fixed;
  text-align: center;
  z-index: 10;
  width: 400px;
}

.copyright {
  color: #777;
  font-size: 11px;
}

.page__report {
  padding: 30px 0;
}

.page__report h2 {
  margin: 0 0 20px 0;
}

.page__report a {
  color: var(--secondary-color);
}

.report__page__wrapper {
  clear: both;
  display: flex;
  height: 540px;
  margin: 0 0 10px 0;
  position: relative;
}

.report__page {
  background-size: cover;
  box-shadow: 0 0 15px #ccc;
  color: #000;
  display: flex;
  flex-basis: 960px;
  font-size: 1rem;
  height: 540px;
}

.report__page--ab {
  background-size: 100% 100%;
  position: relative;
}

.report__page--ab a {
  color: #000;
}

.report__page--bggrey {
  background: url(/assets/images/report-background-grey.jpg) left center
    no-repeat;
}

.report__page h1 {
  font-size: 1.8rem;
  margin: 0 0 20px 0;
}

.report__page--dark {
  background: url(/assets/images/report-background-dark.jpg) right center
    no-repeat;
  color: #fff;
}

.report__page--info {
  background: url(/assets/images/report-people.jpg) left center no-repeat;
  background-size: contain;
  padding-left: 370px;
}

.report__page--educations {
  background: url(/assets/images/report-background-educations.jpg) right center
    no-repeat;
  background-size: contain;
}

.report__page--educations h2 {
  max-width: 200px;
}

.report__page--educations p {
  max-width: 360px;
}

.report__page--educations .report__input {
  height: 280px;
  width: 450px;
}

.report_page--split_11 > div {
  flex-basis: 50%;
}

.report_page--split_21 > div:first-child {
  flex-basis: 66%;
}

.report_page--split_21 > div:nth-child(2) {
  flex-basis: 34%;
}

.report_page--split_12 > div:first-child {
  flex-basis: 34%;
}

.report_page--split_12 > div:nth-child(2) {
  flex-basis: 66%;
}

.report_page--cover {
  align-items: center;
  background: url(/assets/images/report-cover.jpg) center center no-repeat;
  background-size: cover;
  color: #fff;
  display: flex;
  padding-left: 30px;
}

.report__ab__logo {
  position: absolute;
  right: 50px;
  top: 30px;
}

.report__ab__logo img {
  width: 90px;
}

.report__page--ab--zoomed .report__ab__logo img {
  width: 120px;
}

.report__ab__badges {
  bottom: 20px;
  display: block;
  position: absolute;
  right: 30px;
}

.report__ab__badges img {
  width: 170px;
}

.report__page--ab--zoomed .report__ab__badges img {
  width: 170px;
}

.report__print {
  float: right;
  margin: -45px 390px 0 0;
}

.report__tabs {
  margin: 0 0 20px 0;
  position: relative;
  width: 600px;
}

.report__default {
  align-items: center;
  display: flex;
  justify-content: center;
}

.report__default img {
  max-height: 100%;
  max-width: 100%;
}

.report__content__column {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
}

.report__content__header {
  font-size: 1.5rem;
  padding: 100px 50px 20px 50px;
}

.report__info {
  align-items: baseline;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
}

.report__info h2 {
  font-family: DmSansBold;
  font-size: 1.5rem;
}

.report__info h3 {
  font-size: 1.3rem;
  font-weight: 300;
}

.report__info p {
  white-space: pre-wrap;
}

.report__info ul,
.report__info ol {
  margin: 0 0 0 20px;
}

.report__page--ab .report__info {
}

.report__page--ab .report__info .report__input--full {
  height: 300px;
  width: 600px;
}

.report__ab__info {
  padding: 0 0 0 20px;
}

.report__ab__results .report_legend {
  font-size: 0.8rem;
  margin: 0 0 0 10px;
}

.report__ab__results .report_legend_color--primary {
  background: #fad877;
}

.report__ab__results .report_legend_color--highlight {
  background: #eaeaea;
}

.report__ab__results .report_circle_chart__highlight--previous {
  stroke: #eaeaea;
}

.report__ab__results .report_circle_chart__default {
  stroke: #f9f9f9;
}

.report__page--ab .report__ab__results {
  padding-left: 60px;
}

.report__page--ab .report__ab__results .report_circle_chart__label {
  font-size: 1.8rem;
}

.report__cover {
  align-items: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 0 80px;
}

.report__chart {
  align-items: center;
  display: flex;
  font-size: 0.8rem;
  justify-content: flex-start;
}

.report__section__info {
  padding: 0 20px 0 50px;
}

.report__section--padding {
  padding-left: 80px;
  padding-right: 80px;
}

.report__section--padding-noend {
  padding-left: 80px;
  padding-right: 0;
}

.report__items {
  display: flex;
  flex: 1 1;
}

.report__items ul {
  margin: 20px 55px 0 16px;
}

.report__legend__label {
  color: #000;
}

.report__section--hightlighted {
  background: #e8e8e8;
}

.report_circle_chart svg {
  /*transform: rotate(-90deg);*/
}

.report_circle_chart__default {
  stroke: #e8e8e8;
}

.report_circle_chart__highlight {
  stroke: var(--secondary-color);
}

.report_circle_chart__highlight--previous {
  stroke: var(--highlight-color);
}

.gold .report_circle_chart__highlight {
  stroke: var(--tertiary-color);
}

.report_circle_chart__line {
  stroke: var(--warning-color);
}

.report_circle_chart__label {
  font-family: DmSansBold;
  font-size: 3rem;
  font-weight: 700;
}

.report_confirmation__info {
  padding: 60px 0 0 0;
}

.report_confirmation__info h1 {
  font-size: 2rem;
  font-weight: 300;
  margin: 0 0 30px 0;
}

.report_highlights {
  background: url(/assets/images/report-background-blue-side.jpg) center center
    no-repeat;
  background-size: cover;
  display: flex;
}

.report_highlights__list {
  align-items: center;
  display: flex;
  flex-basis: 65%;
  flex-shrink: 0;
  line-height: 1.2rem;
  padding: 0 50px 0 80px;
  white-space: pre-wrap;
}

.report_highlights__list .report__input {
  height: 380px;
  width: 420px;
}

.report_highlights__heading {
  align-items: center;
  color: #fff;
  display: flex;
  padding: 80px;
}

.report_highlights__heading h2 {
  font-family: DmSansBold;
  font-size: 1.5rem;
  text-align: right;
}

.report_ab_highlights {
  padding: 60px 0 0 0;
}

.report_ab_highlights h2 {
  font-family: DmSansBold;
  font-size: 1.45rem;
  margin-bottom: 30px;
}

.report_ab_highlights .report__input {
  height: 380px;
  width: 480px;
}

body .report_ab_highlights .ql-editor ol,
body .report_ab_highlights .ql-editor ul {
  font-size: 0.85rem;
}

.report_outro {
  align-items: center;
  background: url(/assets/images/report-outro-new.jpg) center center no-repeat;
  background-size: cover;
  color: #fff;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
}

.report_outro a {
  color: #fff;
}

.report_outro__content {
  align-items: center;
  display: flex;
  padding: 60px 0;
}

.report_outro__info {
  align-items: center;
  display: flex;
  padding-bottom: 150px;
  text-align: center;
}

.report_outro__info__title {
  border-right: 3px solid #fff;
  font-family: DmSansBold;
  font-size: 2.5rem;
  padding: 20px 30px 20px 10px;
  text-align: left;
}

.report_outro__info__content {
  padding: 20px 0 20px 60px;
  text-align: left;
}

.report_outro__info__title span {
  color: var(--secondary-color);
}

.report_outro__info__selectio {
  padding-right: 650px;
}

.report_outro__info__selectio img {
  height: 50px;
}

.report_outro__info__logos {
  line-height: 45px;
}

.report_outro__info__logos img {
  height: 45px;
  vertical-align: middle;
}

.logo_label {
  padding: 0 0 0 30px;
}

.report__input {
  border: 1px solid #ccc;
  font-size: 0.9rem;
  height: 450px;
  width: 340px;
}

.report__input--full {
  height: 410px;
  width: 790px;
}

.report__input--smaller {
  font-size: 0.8rem;
}

.report__options {
  background: #fff;
  border: 1px solid #999;
  flex: 1 1;
  font-size: 0.8rem;
  margin: 0 0 0 50px;
  overflow: auto;
}

.report__options__tabs {
  display: flex;
}

.report__options__tabs div {
  background: #e8e8e8;
  color: #999;
  cursor: pointer;
  flex: 1 1;
  font-size: 1rem;
  padding: 20px;
  text-align: center;
}

.report__options__tabs .active {
  background: #fff;
  color: #000;
}

.report__options__tab__content {
  display: none;
  padding: 20px;
}

.report__options__tab__content.active {
  display: block;
}

.report__options__checkitem {
  margin: 0;
}

.report__options__checkitem p {
  margin: 0;
  padding: 8px;
}

.report__options__checkitem p:hover {
  background: #eaeaea;
  cursor: pointer;
}

.report_certifier_list {
  display: flex;
  margin: 40px 0 0 0;
}

.report_certifier {
  flex: 1 1;
  text-align: center;
}

.report_certifier h2 {
  font-size: 1rem;
  margin-bottom: 5px;
}

.report_certifier p {
  font-size: 0.9rem;
  margin: 0;
}

.report_category__side {
  background: url(/assets/images/report-background-grey.jpg) -40px center no-repeat;
  background-size: cover;
  align-items: flex-start;
  display: flex;
  flex-basis: 360px;
  flex-direction: column;
  justify-content: center;
  padding-left: 60px;
}

.report_category__side h2 {
  font-size: 1.1rem;
  text-align: left;
  width: 100%;
}

.report_category__side .report_circle_chart__label {
  font-size: 1.3rem;
}

.report_category__side .report_circle_chart__default {
  stroke: #fff;
}

.report_category__content {
  align-items: center;
  display: flex;
  flex: 1 1;
  font-size: 0.8rem;
  justify-content: left;
}

.report_category__content .recharts-wrapper {
  left: -70px;
}

.report_category__highlights {
  flex: 1 1;
  padding: 50px 0 0 0;
}

.report_category__highlights h2 {
  margin: 0 0 15px 0;
}

.report_category__highlights .report__input {
  height: 380px;
  width: 490px;
}

.report__timeline {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.report__timeline__line {
  height: 400px;
  width: 20px;
}

.report__timeline__line:first-child {
  border-right: 1px solid #ccc;
}

.report__timeline__line:nth-child(2) {
  border-left: 1px solid #ccc;
}

.report__timeline__item {
  left: 50%;
  margin-left: -10px;
  position: absolute;
  padding: 0 0 0 35px;
}

.report__timeline__item--left {
  left: auto;
  margin-right: -10px;
  right: 50%;
  text-align: right;
  padding: 0 35px 0 0;
}

.report__timeline__item__dot {
  position: absolute;
  background: var(--secondary-color);
  border-radius: 999px;
  height: 20px;
  width: 20px;
  left: 0;
  top: 10px;
}

.report__timeline__item--left .report__timeline__item__dot {
  left: auto;
  right: 0;
}

.report__timeline__item__title {
  font-weight: bold;
}

.report__timeline__item__subtitle {
  font-size: 0.9rem;
}

.report__badge {
  background: url(/assets/images/report-badge.png) center center no-repeat;
  background-size: contain;
  height: 40px;
  left: 880px;
  position: absolute;
  top: 460px;
  width: 40px;
}

.report__badge--light {
  background-image: url(/assets/images/report-badge-light.png);
}

.report_benchmark {
  display: flex;
  height: 300px;
  margin: 40px 0 0 0;
}

.report_benchmark__reference {
  height: 320px;
  left: 630px;
  position: absolute;
}

.report__page--ab .report_benchmark__reference {
  height: 205px;
}

.report_benchmark__reference__line {
  border-left: 1px dashed #ccc;
  height: 100%;
}

.report_benchmark__reference__label {
  font-size: 0.7rem;
  left: -75px;
  position: absolute;
  top: -25px;
  width: 150px;
}

.report_benchmark__items {
  flex: 1 1;
  padding: 20px 0 0 0;
}

.report_benchmark__item {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative;
  margin: 0 0 20px 0;
  width: 760px;
}

.report_benchmark__item__label {
  flex-shrink: 0;
  font-size: 0.8rem;
  padding: 0 30px 0 0;
  width: 350px;
}

.report_benchmark__item__line {
  flex: 1 1;
  background: #eaeaea;
}

.report_benchmark__item__line__indicator {
  background: var(--secondary-color);
  height: 8px;
}

.report__page--ab .report_benchmark__item__line__indicator {
  background: var(--tertiary-color);
}

.report_benchmark__item__reference {
  color: #666;
  font-size: 0.6rem;
  left: 558px;
  position: absolute;
  top: -11px;
}

.report__benchmark {
  padding: 20px;
}

.report__benchmark h3 {
  margin: 0 0 20px 0;
}

.report__benchmark .input {
  width: 100%;
}

.report_legend {
  margin: 0 0 0 60px;
}

.report_legend_item {
  display: inline-block;
}

.report_legend_color {
  background-color: #c00;
  display: inline-block;
  height: 11px;
  width: 14px;
}

.report_legend_color--primary {
  background-color: var(--secondary-color);
}

.report_legend_color--highlight {
  background-color: var(--highlight-color);
}

.report_legend_label {
  display: inline-block;
  margin: 0 20px 0 5px;
}

.subtitle {
  margin-top: -20px;
}

.recharts-label-list .recharts-label {
  font-size: 0.5rem;
}

.report__ab__chart {
  font-size: 0.8rem;
  padding-right: 60px;
  position: relative;
  top: 50px;
}

.report__ab__chart .recharts-label-list .recharts-label {
  font-size: 0.7rem;
}

#page-elc-benchmark .recharts-label-list .recharts-label {
  font-size: 0.5rem;
}

.report_chart--overall .recharts-label-list .recharts-label {
  font-size: 2.2rem;
  font-family: DmSansBold;
}

.report_midtitle_section {
  align-items: center;
  flex-direction: row;
}

.report_midtitle {
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  flex-basis: 260px;
  flex-grow: 0;
  flex-shrink: 0;
}

.report__risks .report__page {
  background-position-x: -50px;
  background-repeat: no-repeat;
}

.report__risks .report__section--padding {
  padding-left: 60px;
}

body .recharts-legend-wrapper {
  width: 300px !important;
}

body .ql-container {
  font-family: inherit;
  font-size: inherit;
}

body .ql-editor {
  padding: 0;
  overflow-y: hidden;
}

body .ql-editor p {
  font-size: 1rem;
  margin: 0 0 20px 0;
}

body .ql-editor ol,
body .ql-editor ul {
  font-size: 0.9rem;
  margin: 0 0 0 20px;
  padding: 0;
}

body .ql-editor li.ql-indent-1 {
  margin: 0 0 0 20px;
  padding-left: 0;
}

body .ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 0;
}

body .report__input--smaller .ql-editor ol,
body .report__input--smaller .ql-editor ul,
body .report__input--smaller .ql-editor p {
  font-size: 0.85rem;
}

body .ql-editor ol li:not(.ql-direction-rtl),
body .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 0;
}

body .ql-editor ol > li {
  list-style-type: decimal;
}

body .ql-editor ul > li {
  list-style-type: disc;
}

body .ql-editor li:not(.ql-direction-rtl)::before {
  display: none;
}

body .ql-editor ol li:marker,
body .ql-editor ul li:marker {
  font-size: 2rem;
}

@media print {
  @page {
    margin: 0;
    size: 9.6in 5.4in;
  }

  .page {
    padding: 0;
  }

  .header {
    display: none;
  }

  .page__wrapper {
    padding: 0;
  }

  .page__report {
    padding: 0;
  }

  .page__report > h2 {
    display: none;
  }

  .page__notice {
    display: none;
  }

  .report__page {
    box-shadow: none;
    flex-basis: 100%;
    height: 100vh;
  }

  .report__print {
    display: none;
  }

  .report__options {
    display: none;
  }

  .report__tabs {
    display: none;
  }

  .report__badge {
    left: 850px;
    top: 445px;
  }

  .report__page__wrapper {
    height: auto;
    margin: 0;
    page-break-after: always;
  }

  .report__page__wrapper:has(.ql-blank) {
    display: none;
    page-break-after: avoid;
  }

  .report__page__wrapper:last-child {
    page-break-after: avoid;
  }

  .report__input {
    border-color: #fff;
  }
}

